import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// import { connect } from "react-redux";
// import { fetchAllUsers } from "../../store/layout/actions";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "JustPe", link : "/" },
                { title : "Dashboard", link : "#" },
            ]
        }
    }

    render() {
     
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />




                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
