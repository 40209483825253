import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { post } from "../../helpers/api_helper";

const AddUserForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    firmName: "",
    email: "",
    mobile: "",
    address: "",
    pinCode: "",
    state: "",
    city: "",
    userType: "",
    pan: "",
    adhar: "",
    uplineId: "",
  });

  const [uplineOptions, setUplineOptions] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUserTypeChange = async (e) => {
    const userType = e.target.value;
    setFormData({ ...formData, userType });

    if (userType) {
      try {
        const response = await post(
          "/_GetUpline",
          {
            userType: userType,
            modelName: "addUserDetails",
          }
        );
        const uplines = response.uplines;
        console.log(response)
        setUplineOptions(uplines);
      } catch (error) {
        console.error("Error fetching upline options:", error);
      }
    } else {
      setUplineOptions([]);
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
        const response = await post('/addUser',formData);
        console.log(response)

    }catch(error){
        console.error(error)
    }
    console.log(formData);
  };

  return (
    <div style={{padding:'5rem'}}>
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="name">First Name</Label>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="firmName">Firm Name</Label>
        <Input
          type="text"
          name="firmName"
          value={formData.firmName}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="mobile">Mobile Number</Label>
        <Input
          type="number"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">E Mail</Label>
        <Input
          type="email"
          name="email"
          placeholder="abc@email.com"
          value={formData.email}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="address">Address</Label>
        <Input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="pinCode">PIN Code</Label>
        <Input
          type="number"
          name="pinCode"
          value={formData.pinCode}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="state">State</Label>
        <Input
          type="text"
          name="state"
          value={formData.state}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="city">City</Label>
        <Input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="pan">Pan No</Label>
        <Input
          type="text"
          name="pan"
          value={formData.pan}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="adhar">Aadhar No.</Label>
        <Input
          type="text"
          name="adhar"
          value={formData.adhar}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="userType">User Type</Label>
        <Input
          type="select"
          name="userType"
          value={formData.userType}
          onChange={handleUserTypeChange}
        >
          <option value="">Select User Type</option>
          <option value="1">Super Distributor</option>
          <option value="2">Distributor</option>
          <option value="3">Retailer</option>
          <option value="4">API User</option>
        </Input>
      </FormGroup>
      {uplineOptions && (
        <FormGroup>
          <Label for="uplineId">Select Upline</Label>
          <Input
            type="select"
            name="uplineId"
            value={formData.uplineId}
            onChange={handleInputChange}
          >
            <option value="">Select Upline</option>
            {uplineOptions.map((upline) => (
              <option key={upline.id} value={upline.id}>
                {upline.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
      <Button type="submit">Submit</Button>
    </Form>
    </div>
  );
};

export default AddUserForm;
