import {
    GET_ALL_PACKAGE,
    PACKAGE_SUCCESS,
    PACKAGE_FAIL,
  } from "./actionTypes";
  
  const initialState = {
    message: null,
    loading: false,
    packages: [],
  };
  
  const packagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_PACKAGE:
        return {
          ...state,
          loading: true,
          message:null
        };
  
      case PACKAGE_SUCCESS:
        console.log(action.payload);
        return {
          ...state,
          loading: false,
          packages: action.payload,
        };
  
      case PACKAGE_FAIL:
        return {
          ...state,
          loading: false,
          message: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default packagesReducer;
  