import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane, Badge, Table, ListGroup, ListGroupItem } from 'reactstrap';
import  '../user/profile.css';

const Profile = () => {

  return (
    <React.Fragment>
      <div className="page-content">
  
      <Row>
        <Col xl="8" md="8">
          <Card>
            <CardBody className="pb-0">
              <Row className="align-items-center">
                <Col md="3">
                  <div className="text-center border-end">
                    <img src="https://bootdey.com/img/Content/avatar/avatar1.png" className="img-fluid avatar-xxl rounded-circle" alt="" />
                    <h4 className="text-primary font-size-20 mt-3 mb-2">Jansh Wells</h4>
                    <h5 className="text-muted font-size-13 mb-0">Web Designer</h5>
                  </div>
                </Col>
                <Col md="9">
                  <div className="ms-3">
                    <div>
                      <CardTitle tag="h4" className="mb-2">Biography</CardTitle>
                      <p className="mb-0 text-muted">Hi I'm Jansh, has been the industry's standard dummy text To an English person alteration text.</p>
                    </div>
                    <Row className="my-4">
                      <Col md="12">
                        <div>
                          <p className="text-muted mb-2 fw-medium"><i className="mdi mdi-email-outline me-2"></i>Janshwells@probic.com</p>
                          <p className="text-muted fw-medium mb-0"><i className="mdi mdi-phone-in-talk-outline me-2"></i>418-955-4703</p>
                        </div>
                      </Col>
                    </Row>
                    <Nav tabs className="nav-tabs-custom border-bottom-0 mt-3 nav-justified">
                      <NavItem>
                        <NavLink href="#projects-tab" active>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                          <span className="d-none d-sm-block">Projects</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink href="https://bootdey.com/snippets/view/profile-task-with-team-cards" target="__blank">
                          <span className="d-block d-sm-none"><i className="mdi mdi-menu-open"></i></span>
                          <span className="d-none d-sm-block">Tasks</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink href="https://bootdey.com/snippets/view/profile-with-team-section" target="__blank">
                          <span className="d-block d-sm-none"><i className="mdi mdi-account-group-outline"></i></span>
                          <span className="d-none d-sm-block">Team</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

        
        </Col>
        <Col xl="4" md="4">
        

          
        </Col>
      </Row>

    
   
    </div>
    </React.Fragment>
  );
};

export default Profile;
