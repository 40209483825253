import React, { useState } from 'react';
import MiniWidgets from '../RetailerDashboard/MiniWidgets';
import Breadcrumbs from '../Common/Breadcrumb';
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  Card
} from "reactstrap";
function SuperAdminDashboard() {
  const greeting = 'Hello Function Component!';
  const [breadCrumb, setBreadCrumb] = useState([{ title: "JustPe", link: "/" },
    { title: "Admin", link: "#" }])
  const [report, setReport] = useState([
    { icon: "ri-stack-line", title: "Number of Users", value: "1452", rate: "2.4%", desc: "From previous period" },
    { icon: "ri-store-2-line", title: "Total AEPS", value: "₹ 38452", rate: "2.4%", desc: "From previous period" },
    { icon: "ri-store-2-line", title: "Total Cash W", value: "₹ 38452", rate: "- 2.4%", desc: "From previous period" },
    { icon: "ri-briefcase-4-line", title: "Total DMT", value: "₹ 15.4", rate: "2.4%", desc: "From previous period" }]);
  return <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Admin" breadcrumbItems={breadCrumb} />
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={report} />
              </Row>
            </Col>
          </Row>
          </Container>
          </div>
    </React.Fragment>
}

export default SuperAdminDashboard;