import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Table,
  Spinner,
} from "reactstrap";
import { post } from "../../helpers/api_helper";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PackageCommisionList = (props) => {
  const [packageCommision, setPackageCommision] = useState([]);
  const history = useHistory();
  const [selectedId, setSelectedId] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllcommision();
  }, [currentState]);

  const handleFieldChange = (event, index, field) => {
    const { value } = event.target;
    setPackageCommision((prevCommisions) => {
      const updatedCommisions = [...prevCommisions.packdetails];
      updatedCommisions[index][field] = value;
      return { ...prevCommisions, packdetails: updatedCommisions };
    });
  
    setSelectedData((prevData) => {
      const updatedData = { ...prevData, ...packageCommision.packdetails[index] };
      updatedData[field] = value;
      return updatedData;
    });
  };

  const getAllcommision = async () => {
    try {
      setLoading(true);
      const selectedPackage = history.location.state.selectedPackage;
      let dataRes = await post(`/viewPackagewiseCommCharge`, {
        id: selectedPackage.id,
        package_id: selectedPackage.package_id,
        package_name: selectedPackage.package_name,
        service_type: selectedPackage.service_type,
        owner: selectedPackage.owner,
      });
      setPackageCommision(dataRes);
      setLoading(false);
      console.log(dataRes);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await post("/updatemyPackage", selectedData);
      console.log(response);
      toast(response.message)
    //reset
      setSelectedId(0);
      setSelectedData([]);
    } catch (err) {
      toast.error(err.response.message)
      console.log(err.response.message);
    }
  };
  const handleInlineEdit = (index) => {
    if (index === selectedId) {
      setSelectedId(0);
    } else {
      setSelectedId(index); 
    }
  };

  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setCurrentState(currentPage);
    getAllcommision();
    console.log(currentPage);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner
                      style={{ width: "2rem", height: "2rem" }}
                      color="primary"
                    />
                  </div>
                )}
                <h4 className="card-title">Packages </h4>
                <div className="table-responsive">
                  <Table striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Package Id</th>
                        <th>Service</th>
                        <th>Commision</th>
                        <th>Comm Type</th>
                        <th>Charge</th>
                        <th>Charge Type</th>
                        <th>Slab</th>
                        <th>Update</th>
                      </tr>
                    </thead>

                    <tbody>
                      {packageCommision.packdetails !== undefined &&
                        packageCommision.packdetails.map((data, index) => {
                          const isEditable = index === selectedId;
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.package_id}</td>
                              <td>{data.serviceProvider}</td>
                              <td>
                                {isEditable ? (
                                  <input
                                    type="text"
                                    value={data.comm}
                                    onChange={(event) =>
                                      handleFieldChange(event, index, "comm")
                                    }
                                  />
                                ) : (
                                  data.comm
                                )}
                              </td>
                              <td>
                                {isEditable ? (
                                  <select
                                    value={data.comm_type}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        index,
                                        "comm_type"
                                      )
                                    }
                                  >
                                    <option value="PERCENTAGE">PERCENTAGE</option>
                                    <option value="RUPEE">RUPEE</option>
                                  </select>
                                ) : (
                                  data.comm_type
                                )}
                              </td>
                              <td>
                                {isEditable ? (
                                  <input
                                    type="text"
                                    value={data.charge}
                                    onChange={(event) =>
                                      handleFieldChange(event, index, "charge")
                                    }
                                  />
                                ) : (
                                  data.charge
                                )}
                              </td>
                              <td>
                                {isEditable ? (
                                  <select
                                    value={data.charge_type}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        event,
                                        index,
                                        "charge_type"
                                      )
                                    }
                                  >
                                    <option value="PERCENTAGE">PERCENTAGE</option>
                                    <option value="RUPEE">RUPEE</option>
                                  </select>
                                ) : (
                                  data.charge_type
                                )}
                              </td>
                              <td>
                                {data.slab1} - {data.slab2}
                              </td>
                              <td>
                                {index === selectedId ? (
                                  <>
                                    <Button color="danger" outline onClick={handleUpdate}>
                                      Update
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    color="primary"
                                    outline
                                    onClick={() => handleInlineEdit(index)}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>

          <Pagination aria-label="pagination">
            <PaginationItem disabled={currentState <= 0}>
              <PaginationLink
                onClick={(e) => handlePagination(e, currentState - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            {[...Array(pageState)].map((_, i) => (
              <PaginationItem active={i === currentState} key={i}>
                <PaginationLink onClick={(e) => handlePagination(e, i)} href="#">
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentState >= pageState - 1}>
              <PaginationLink
                onClick={(e) => handlePagination(e, currentState + 1)}
                next
                href="#"
              />
            </PaginationItem>
          </Pagination>
        </Container>
        <ToastContainer/>
      </div>
      
    </>
  );
};

export default PackageCommisionList;
