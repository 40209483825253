import { Switch, Route, useRouteMatch } from 'react-router-dom';
import React, { Component, useState } from "react";
import {
  Col,
  Container,

  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  Card
} from "reactstrap";
import { rechargeTypes, bankingServiceTypes } from '../menuConstants';

import classnames from "classnames";
import MiniWidgets from '../MiniWidgets';
import Breadcrumbs from '../../Common/Breadcrumb';
const RechargeUi = () => {
  const { path } = useRouteMatch();
  const [activeTab, setActiveTab] = useState(1);
  const [breadCrumb, setBreadCrumb] = useState([{ title: "Nazox", link: "/" },
  { title: "Dashboard", link: "#" }])
  const [report, setReport] = useState([
    { icon: "ri-stack-line", title: "Number of Recharge", value: "1452", rate: "2.4%", desc: "From previous period" },
    { icon: "ri-store-2-line", title: "Total AEPS", value: "₹ 38452", rate: "2.4%", desc: "From previous period" },
    { icon: "ri-store-2-line", title: "Total Cash W", value: "₹ 38452", rate: "2.4%", desc: "From previous period" },
    { icon: "ri-briefcase-4-line", title: "Total DMT", value: "₹ 15.4", rate: "2.4%", desc: "From previous period" }]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Retailer Home" breadcrumbItems={breadCrumb} />
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={report} />
              </Row>
            </Col>
          </Row>


          <Row>
            <Container fluid>
              <h5>Bill Payment Services</h5>
              <Card>
                <div className="d-lg-flex ">
                  <div className="chat-leftsidebar-nav">
                    <Nav pills justified tabs>
                      {
                        rechargeTypes.map((item, index) => (
                          <NavItem>
                            <NavLink href={`${item.path}`}
                            >
                              <i className={`${item.icon} font-size-20`}></i>
                              <span className="mt-2 d-none d-sm-block">{item.name}</span>
                            </NavLink>
                          </NavItem>

                        ))
                      }
                    </Nav>
                  </div>
                </div>
              </Card>
            </Container>
          </Row>

          <Row>
            <Col xl={2}>
              <Container fluid>
                <h5>Banking Services</h5>
                <Card>
                  <div className="d-lg-flex ">
                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified tabs>
                        {
                          bankingServiceTypes.map((item, index) => (
                            <NavItem>
                              <NavLink href={`${item.path}`} >
                                <i className={`${item.icon} font-size-20`}></i>
                                <span className="mt-2 d-none d-sm-block">{item.name}</span>
                              </NavLink>
                            </NavItem>
                          ))
                        }
                      </Nav>
                    </div>
                  </div>
                </Card>
              </Container>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default RechargeUi;