import React, { Component } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, Button, CardTitle, CardBody, CardSubtitle, CardText, Container, Row, Col, Table, Label, Alert } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { post, get } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';


class AssignPackage extends Component {

  constructor(props) {
    super(props);
    this.state = { loader: false, options: [], packageOptions: [], selectedPackage: '', username: "admin@themesdesign.in", password: "123456", inputValue: '', selectedValue: '' }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);

  }
  componentDidMount() {
    this.loadOptions();
    this.packageOptions();
  }

  handleInputChange = value => {
    console.log(value);
    this.setState({ inputValue: value })
  };

  // handle selection
  handleChange = value => {
    console.log(value);
    this.setState({ selectedValue: value })
  }

  handlePackageChange = value => {
    console.log(value);
    this.setState({ selectedPackage: value })
  }


  // load options using API call
  loadOptions = async (inputValue) => {
    let data = await get(`/allusers?page=0&&size=1200`);
    let finalD = data.userlist.map(data => {
      let ds = { value: data.login, label: data.firstName + ' ' + data.lastName + "(" + data.phone + ")" };
      return ds;
    });
    this.setState({ options: finalD })
  };


  packageOptions = async (inputValue) => {
    let data = await get(`/getPackagesDetails?page=0&&size=1300`);
    let finalD = data.packageList.map(data => {
      let ds = { value: data.packageId, label: data.packageName + '-' + data.packageId + "(" + data.serviceType + ")" };
      return ds;
    });
    this.setState({ packageOptions: finalD })
  };

  async handleSubmit(event, values) {
    console.log(values);
    try {
      let dataRes = await post('/package', values);
      toast.success("Paclkage Created With  !" + dataRes.packageName + 'and Package Id ' + dataRes.packageId, {
        position: toast.POSITION.TOP_LEFT
      });
      this.setState({ packageName: '' });

    } catch (err) {
      this.setState({ error: err.response.data.message })
      console.log(err.response.data.message)
    }



  }


  handleAssignPackage = () => {
    this.setState({ loader: true });
    let dataAssign = { "packageId": this.state.selectedPackage.value, "loginId": this.state.selectedValue.value };
    post("/assignPackage", dataAssign).then((data) => {
      toast.success("Package Assigned ", {
        position: toast.POSITION.TOP_LEFT
      });
      this.setState({ loader: false });

    }).catch(err => {
      toast.error(err.response.data.detail, {
        position: toast.POSITION.TOP_LEFT
      });
      this.setState({ loader: false });
    })


  }
  handleInvalidSubmit(event, errors, values) {
    console.log(errors, values)
    this.setState({ errors, values });
  }
  render() {
    console.log(this.props.loginError)
    return (
      <><React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>



            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <h4 className="card-title">Assign Package</h4>
                    <p className="card-title-desc">Assign Package to User</p>

                    <form>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Single Select</Label>
                            <Select
                              value={this.state.selectedValue}
                              onChange={this.handleChange}
                              options={this.state.options}
                              classNamePrefix="select2-selection"
                            />

                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Select Package</Label>
                            <Select
                              value={this.state.selectedPackage}
                              onChange={this.handlePackageChange}
                              options={this.state.packageOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="mb-5">
                            <div className="text-left mt-4">
                              <Button color="primary" type="button" onClick={this.handleAssignPackage}
                                className="waves-effect waves-light">
                                {this.state.loader ? <Spinner size="sm">Loading...</Spinner> : ''} Assign</Button>
                            </div>

                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>



          </Container>
        </div>
      </React.Fragment>
      </>)

  }
}

const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {})(AssignPackage));

