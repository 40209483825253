import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { GET_ALL_PACKAGE,GET_PACKAGE_DETAILS } from './actionTypes';
import { getPackages } from '../../helpers/fackBackend_Helper';
import { fetchPackageFail,fetchPackageSuccessFull } from './actions';
// AUTH related methods
// import { postLogin } from '../../../helpers/fackBackend_Helper';
// import { getFirebaseBackend } from '../../../helpers/firebase_helper';


//If user is login then dispatch redux action's are directly from here.
function* getAllPackages({ payload: {data, history } }) {
        try {
                const response = yield call(getPackages,data);
                // localStorage.setItem("authUser", JSON.stringify(response));
                yield put(fetchPackageSuccessFull(response));
        
        } catch (error) {
            console.log(error)
            yield put(fetchPackageFail(error));
        }
}

// function* logoutUser({ payload: { history } }) {
//     try {
//         localStorage.removeItem("authUser");

//         if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
//             const response = yield call(fireBaseBackend.logout);
//             yield put(logoutUserSuccess(response));
//         }

//         history.push('/login');
//     } catch (error) {
//         yield put(apiError(error));
//     }
// }


 function* packageDetails({ payload: { history } }) {

}
export function* watchGetAllPackages() {
    yield takeEvery(GET_ALL_PACKAGE,getAllPackages)
}

export function* watchFindPackage() {
    yield takeEvery(GET_PACKAGE_DETAILS,packageDetails)
}

function* packageSaga() {
    yield all([
        fork(watchGetAllPackages),
        fork(watchFindPackage),
    ]);
}

export default packageSaga;