import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { API_URL, post } from '../../helpers/api_helper';
import axios from 'axios';
import { checkLogin } from '../../store/actions';
import logodark from "../../assets/images/tez-logo.png";
import logolight from "../../assets/images/tez-logo.png";
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState("999999999");
  const [password, setPassword] = useState("test123");
  const [loginError, setLoginError] = useState("");
  const [showotp, setShowotp] = useState(false);
  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  const handleSubmit = async (event, values) => {
    event.preventDefault();
    const payload = {
      'mobile': values.username,

    };

    if (showotp) {
      await axios.post(API_URL + '/verifyOtp?mobile=' + values.username + '&otp=' + values.password)
        .then(response => {
          setShowotp(true)
          setLoginError("");
          console.log(response)
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
          localStorage.setItem('token', response.data.data.token)
          const role =response.data.data.role;
          console.log(role)
          if (role==="ADMIN"){
           history.push('/superadmin')
          }
          if (role==="DISTRIBUTOR"){
           history.push('/distributor')
          }
          if (role==="RETAILER"){
           history.push('/retailer')
          }
          if (role.includes("APIUSER")){
           history.push('/apiuser')
          }
          else{
           history.push('/')
          }

          
        })
        .catch(error => {
          // Handle login error here
          console.error('Login error:', error);
          setLoginError("Invalid username or password");
        });
    }

    await axios.post(API_URL + '/login?mobile=' + values.username)
      .then(response => {
        setShowotp(true)
        //   setLoginError("");
        //   localStorage.setItem("authUser", JSON.stringify(response));
        //   localStorage.setItem('token',response.accessToken )
        //  history.push("/dashboard")
      })
      .catch(error => {
        // Handle login error here
        console.error('Login error:', error);
        setLoginError("Invalid username or password");
      });
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img src='https://justpe.in/wp-content/uploads/2024/04/Just-2.png' alt="" height="50" className="auth-logo logo-dark mx-auto" />
                              <img src='https://justpe.in/wp-content/uploads/2024/04/Just-2.png' alt="" height="50" className="auth-logo logo-light mx-auto" />
                            </Link>
                          </div>
                          <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                          <p className="text-muted">Sign in to continue to Just pe.</p>
                        </div>
                        {loginError ? <Alert color="danger">{loginError}</Alert> : null}
                        <div className="p-2 mt-5">
                          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">Mobile</Label>
                              <AvField name="username" value={username} type="text" className="form-control" id="username" placeholder="Enter Mobile" />
                            </div>
                            {showotp ? <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Enter OTP</Label>
                              <AvField name="password" value={password} type="number" className="form-control" id="userpassword" placeholder="Enter OTP" />
                            </div>
                              : ''}

                            <div className="form-check">
                              <Input type="checkbox" className="form-check-input" id="customControlInline" />
                              <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                            </div>
                            <div className="mt-4 text-center">
                              <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In or Signup</Button>
                            </div>

                          </AvForm>
                        </div>
                        <div className="mt-5 text-center">

                          <p>© 2023 JustPe. Crafted with <i className="mdi mdi-heart text-danger"></i> by Dubtap Technology LLP</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Login;
