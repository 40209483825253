import React, { Component, StrictMode, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import { useParams } from 'react-router-dom';

import { Card, Button, CardTitle, ButtonGroup, CardBody, Spinner, CardSubtitle, CardText, Container, Row, Col, Table, Label, Alert, Form, FormGroup, Input } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { connect } from 'react-redux';
import { get, post, put } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// availity-reactstrap-validation
import { AvForm, AvField, AvRadio } from 'availity-reactstrap-validation';
const selectOptions = [
  { value: 'AEPS', label: 'AEPS' },
  { value: 'BBPS', label: 'BBPS' },
  { value: 'DMT', label: 'DMT' }
];

const GridExample = (props) => {
  const [editedRows, setEditedRows] = useState({});

  const [rowData, setRowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    console.log(props.data)
    setRowData(props.data)
  }, props.data);


  const getData = () => {
    console.log(rowData)
  }


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);
    },
    onMouseEnter: (e, row, rowIndex) => {
      // console.log(`enter on row with index: ${rowIndex}`);
    },
    onBlur: (e, row, rowIndex) => {
      console.log(`enter on row with index:  ${row}`);
    }
  };


  const handleSubmit = async () => {


    const cleanedRowData = rowData.map(item => {
      const { subscriptionCommissionId, serviceTypeValue, ...rest } = item;
      return rest;
    });
    console.log(cleanedRowData)

    let dataSub = {
      userType: props.userType,
      name: props.name,
      charge: props.charge,
      subscriptionAllowed: props.subscriptionAllowed,
      defaultPacakge: props.defaultPacakge
      , "monthlyDiscountPercentage": 0,
      "quaterlyDiscountPercentage": 0,
      "yealryDiscountPercentage": 0,
    };
    dataSub.subscriptionCommisionRequest = cleanedRowData;
    console.log(dataSub)
    if (dataSub.userType != '' && dataSub.userType != undefined && dataSub.name != '' && dataSub.name != undefined && dataSub.charge != '' && dataSub.charge != undefined) {
      try {
        setLoader(true)
        let dataRes = await post('/subscription/create', dataSub);
        toast.success("Subscription Created With  !" + dataRes.data.name, {
          position: toast.POSITION.TOP_LEFT
        });
        history.pushState("/subscription")
      } catch (ex) {
        setLoader(false)

      }
      setLoader(false)

    } else {
      alert("Required Fileds Empty")
    }

  }
  const actionFormatter = (cell, row) => {
    if (editedRows[row.subscriptionCommissionId]) {
      return <Button onClick={() => handleAction(row)}>Update</Button>;
    } else {
      return <a>View</a>;
    }

  };
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([

    { dataField: "subscriptionCommissionId", text: 'Id' },
    { dataField: "slabrangeStart", text: 'Slab Start', filter: numberFilter() },
    { dataField: "slabRangeEnd", text: 'Slab End', filter: numberFilter() },
    { dataField: "operatorId", text: 'Operator Id', editable: false },
    { dataField: "operatorName", text: 'Op Name', editable: false,filter: textFilter() },

    { dataField: "charge", text: 'Charge', filter: numberFilter(), sort: true },
    {
      text: "Charge Type",
      dataField: "chargeType",
      editor: {
        type: Type.SELECT,
        options: [{
          value: 'Percentage',
          label: 'Percentage'
        }, {
          value: 'Flat',
          label: 'Flat'
        },]
      }, filter: textFilter()

    },
    { dataField: "commission", text: 'Commision', filter: numberFilter(), sort: true },
    { dataField: "commissionType", text: 'Comm Type', filter: textFilter() },
    { dataField: "gstPercentage", text: 'GS(%)', filter: numberFilter() },
    { dataField: "tdsPercentage", text: 'TDS (%)', filter: numberFilter() },
    {
      dataField: "serviceType", text: 'Service Type', editable: false, formatter: cell => selectOptions.find(opt => opt.label === cell).label,
      filter: selectFilter({
        options: selectOptions
      })
    }

  ]);

  const handleAction = (row) => {
    console.log("Perform action for row", row);
    // You can add any logic here, for example, an API call to update the backend
  };


  const handleCellEdit = (oldValue, newValue, row, column) => {
    if (oldValue !== newValue) {

      const updatedData = rowData.map(item =>
        item.subscriptionCommissionId === row.subscriptionCommissionId ? { ...item, [column.dataField]: newValue } : item
      );
      setRowData(updatedData);
      setEditedRows(prev => ({ ...prev, [row.subscriptionCommissionId]: true }));
      console.log(editedRows, rowData)

      props.updatedata(row)

    }

    // console.log(oldValue, newValue, column, row);
  };




  return (
    <React.Fragment>
      <Row>
        <Col md="4"></Col>
        <Col md="8">
          <div className="mb-8">
            <ButtonGroup>
              <Button onClick={getData} outline>
                View Data
              </Button>
              <Button onClick={handleSubmit} outline>
                Create Subscription
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>


      <div
        // applying the grid theme
        style={{ height: ' auto' }} // the grid will fill the size of the parent container
      >

        {loader === true ? <Row>
          <Col md="4"></Col>
          <Col md="6">
            <Spinner
              color="primary"
              style={{
                height: '3rem',
                width: '3rem'
              }}
            >
              Loading...
            </Spinner>
          </Col>
        </Row> : ''

        }

        <br></br>

        <BootstrapTable
          style={{ height: 'auto' }}
          rowEvents={rowEvents}
          keyField="subscriptionCommissionId"
          data={rowData}
          columns={colDefs}
          filter={filterFactory()}
          pagination={paginationFactory()}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: handleCellEdit,
          })}
        />



      </div>

    </React.Fragment>
    // wrapping container with theme & size

  )
}

const ViewSubscription = () => {
  const [loader, setLoader] = useState(false);
  const [userType, setUserType] = useState("");
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [charge, setCharge] = useState(0.00);
  const [subscriptionAllowed, setSubscriptionAllowed] = useState(false);
  const [defaultPackage, setDefaultPackage] = useState(false);
  let { id } = useParams();
  useEffect(() => {

    console.log(id)
    const fetchData = async () => {
      setLoader(true);
      const objSearch = {
        "pageNumber": 0,
        "pageSize": 100000,
        "userType": "RETAILER",
        "active": true,
        "defaultPackage": true
      };
      try {
        const dataRes = await get('/subscription/' + id + '/list');
        setData(dataRes.data);
        setName(dataRes.data.name)
        setCharge(dataRes.data.charge)
        setSubscriptionAllowed(dataRes.data.subscriptionAllowed);
        setDefaultPackage(dataRes.data.defaultPacakge)
        setUserType(dataRes.data.userType)
        console.log(dataRes.data)
      } catch (error) {
        console.error(error);
      }
      setLoader(false);
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs only once after the initial render



  const updateData = async (row) => {
    let dataSub = {
      // name:name,
      // charge: charge,
      // subscriptionAllowed: subscriptionAllowed,
      // "monthlyDiscountPercentage": 0,
      // "quaterlyDiscountPercentage": 0,
      // "yealryDiscountPercentage": 0,

    };

    let dataRowObjArr = [];
    dataRowObjArr.push(row);


    const cleanOperatornameData = dataRowObjArr.map(item => {
      const { serviceTypeValue, operatorName, ...rest } = item;
      return rest;
    });

    dataSub.subscriptionCommisionRequest = (cleanOperatornameData)

    console.log(dataSub)
    console.log(row)
    try {
      setLoader(true)
      let dataRes = await put('/subscription/' + id + '/update', dataSub);
      toast.success("Subscription Updated With  !" + dataRes.data.name, {
        position: toast.POSITION.TOP_LEFT
      });
      setData(dataRes.data);
      setName(dataRes.data.name)
      setCharge(dataRes.data.charge)
      setSubscriptionAllowed(dataRes.data.subscriptionAllowed);
      setDefaultPackage(dataRes.data.defaultPacakge)
    } catch (ex) {
      setLoader(false)

    }
    setLoader(false)

  }

  const handleUserTypeChange = async (ev) => {
    setUserType(ev.target.value);
    setLoader(true);
    const objSearch = {
      "pageNumber": 0,
      "pageSize": 100000,
      "userType": ev.target.value,
      "active": true,
      "defaultPackage": true
    };
    try {
      const dataRes = await post('/subscription/search/list', objSearch);
      setData(dataRes.data.content);
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  const handlePackageNameChange = (ev) => {
    setName(ev.target.value);
  };

  const handleChargeChange = (ev) => {
    setCharge(ev.target.value);
  };

  const handleAllowAllChange = (ev) => {
    setSubscriptionAllowed(ev.target.checked);
  };

  const handleDefaultChange = (ev) => {
    setDefaultPackage(ev.target.checked);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Row>
          <Col lg={12}>
            <Card>
              <h3 style={{ textAlign: 'center' }}>View Subscription</h3>
              <p style={{ textAlign: 'center' }}>Fetch Subscription Based On User Type and Update Data and Click On Create Subscription</p>
              <CardBody>
                <Row>
                  {/* <Col md="3">
                    <FormGroup>
                      <Label for="exampleSelect">Select User Type</Label>
                      <Input type="select" name="select" id="exampleSelect" onChange={handleUserTypeChange}>
                        <option value="">Select User Type</option>
                        <option value="RETAILER">Retailer</option>
                        <option value="DISTRIBUTOR">Distributor</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                  <Col md="3">
                    <FormGroup>
                      <Label for="namePack">Package Name</Label>
                      <Input type="text" name="name" id="namePack" value={name} placeholder="Basic" onChange={handlePackageNameChange} />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="charge">Charge</Label>
                      <Input type="number" name="charge" id="charge" placeholder="100.00" value={charge} onChange={handleChargeChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup check>
                      <Input type="checkbox" checked={subscriptionAllowed} onChange={handleAllowAllChange} />
                      <Label check>Allowed To All Users</Label>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup check>
                      <Input type="checkbox" checked={defaultPackage} onChange={handleDefaultChange} />
                      <Label check>Default Sub</Label>
                    </FormGroup>
                  </Col>
                </Row>
                {loader ? (
                  <Row>
                    <Col md="4"></Col>
                    <Col md="6">
                      <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}>Loading...</Spinner>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  {data != undefined && data.subscriptionCommisionResponse ? (
                    <GridExample
                      updatedata={updateData}
                      subId={id}
                      isEditMode={false}
                      data={data.subscriptionCommisionResponse}
                      userType={userType}
                      name={name}
                      charge={charge}
                      subscriptionAllowed={subscriptionAllowed}
                      defaultPacakge={defaultPackage}
                    />
                  ) : null}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {})(ViewSubscription));

