import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import 'ag-grid-enterprise';
import { withRouter, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, selectFilter } from 'react-bootstrap-table2-filter';

import { AgGridReact } from 'ag-grid-react';
import {
  CellEditingStoppedEvent,
  ColDef,
  RowEditingStoppedEvent,
} from 'ag-grid-react';
import { Spinner, Col, Row, Card, CardBody, Button, Input } from 'reactstrap';
import { ColDefUtil } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { get } from '../../helpers/api_helper';

const SubscriptionList = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState([]);
  
  useEffect(async () => {
     
     getAllPackage();
     
  }, loader);

  const onGridReady = (params) => {
    console.log(params)
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getAllPackage = async () => {
    try {
      setLoader(true)
      const response = await get("/subscription/list");
      console.log(response.data)
      setRowData(response.data)
      setLoader(false);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleView = (row) => {
    // Handle the view action
    alert(`Viewing details of ${row.name}`);
  };
  
  const handleAdd = (row) => {
    // Handle the add action
    alert(`Adding ${row.name}`);
  };


  
  const getRowData = () => {
    let rowData = [];
    if(gridApi!=null ){
      gridApi.forEachNode(node => rowData.push(node.data));
      alert(JSON.stringify(rowData))
    }
    
  }
 
  const handleCheckboxChange = (rowIndex) => {
    console.log(rowIndex)
    
    //newData[rowIndex].subscriptionAllowed = !newData[rowIndex].subscriptionAllowed;
   // setRowData(newData);
  };
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    {field:"subscriptionId"},
    { field: "userType" },
    { field: "charge" },
    { field: "name" },
    
    {
      headerName: 'subscriptionAllowed',
      field: 'subscriptionAllowed',
      cellRenderer: (params) => (
        <Input
          type="checkbox"
          checked={params.value}
          onChange={() => handleCheckboxChange(params.rowIndex)}
        />
      ),
    },
    {field:"defaultPacakge"},
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <div className='col-md-5'>
          <Button outline onClick={() => handleView(params.data)} style={{ marginRight: '10px' }}>
            View
          </Button>
          <Button outline onClick={() => handleAdd(params.data)}>
            Add
          </Button>
        </div>
      ),
    },
  ]);

  return (
    <React.Fragment>

      <div className="page-content">


        <Row>
          <Col lg={12}>
            <Card>
              <h3 style={{ textAlign: 'center' }}>Create Subscription for User Type </h3>
              <p style={{ textAlign: 'center' }} >Fetch Subscription Based On User Type and Update Datab and Click On Create Subscription</p>
              <CardBody>
                <Row>
                  <Col md="8">

                  <div className="example-wrapper">
          <div
            style={{
              marginBottom: '5px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
          </div>
          <div
            // applying the grid theme
            style={{ height: ' auto' }} // the grid will fill the size of the parent container
          >

            {loader === true ? <Row>
              <Col md="4"></Col>
              <Col md="6">
                <Spinner
                  color="primary"
                  style={{
                    height: '3rem',
                    width: '3rem'
                  }}
                >
                  Loading...
                </Spinner>
              </Col>
            </Row> : ''

            }

            <br></br>

            <div
   className="ag-theme-alpine"  // applying the grid theme
  style={{ height: 500 }} // the grid will fill the size of the parent container
 >
   <AgGridReact
    onGridReady={onGridReady}
       rowData={rowData}
       columnDefs={colDefs}
   />
 </div>
          </div>
        </div>

                
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

      
      </div>


    </React.Fragment>
  );
};

export default SubscriptionList;