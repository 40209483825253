import React, { Component, StrictMode, useState, useMemo } from 'react';

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import { Card, Button, CardTitle, ButtonGroup, CardBody, CardSubtitle, CardText, Container, Row, Col, Table, Label, Alert, Form, FormGroup, Input, Spinner } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { connect } from 'react-redux';
import { get, post, put } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





// availity-reactstrap-validation
import { AvForm, AvField, AvRadio } from 'availity-reactstrap-validation';

const GridExample = (props) => {
  const [loading, setLoading] = useState(false); // State to handle loader visibility

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  LicenseManager.setLicenseKey("your license key")

  const gridRef = useRef < AgGridReact > (null);
  // Row Data: The data to be displayed.
  useEffect(() => {
    console.log(props.data)
    setRowData(props.data)
  });


  const LinkCellRenderer = (props) => {
    const { value } = props;
    const url = `/subscribe/view/${value}`; // Construct your URL here

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    );
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleView = (row) => {
    // Handle the view action
    alert(`Viewing details of ${row.name}`);
  };

  const handleAdd = (row) => {
    // Handle the add action
    alert(`Adding ${row.name}`);
  };


  const handleChargeUpdate = async (params) => {
    let rowDataA = [];
    params.api.forEachNode(node => rowDataA.push(node.data));
    console.log(rowDataA, params)
    setLoading(true);
    let dataSub = { "subscriptionId": params.data.subscriptionId, "charge": params.data.charge }
    let dataRes = await put('/subscription/' + params.data.subscriptionId + '/update', dataSub);
    console.log(rowDataA[params.rowIndex]);
    // rowDataA[params.rowIndex].charge = params.data.charge;
    // setRowData(rowDataA);
    setLoading(false);

  };

  const handleCheckboxChange = async (rowIndex, param) => {

    // console.log(rowData)
    console.log(rowData, param)

    let rowDataA = [];
    param.api.forEachNode(node => rowDataA.push(node.data));
    console.log(rowDataA)

    setLoading(true)
    let dataSub = { "subscriptionId": param.data.subscriptionId, subscriptionAllowed: !rowDataA[rowIndex].subscriptionAllowed }
    let dataRes = await put('/subscription/' + param.data.subscriptionId + '/update', dataSub);

    rowDataA[rowIndex].subscriptionAllowed = !rowDataA[rowIndex].subscriptionAllowed;
    setRowData(rowDataA);
    setLoading(false)

    // toast.success("Subscription Updated With  !" + dataRes.data.name, {
    //   position: toast.POSITION.TOP_LEFT
    // });

  };
  // { field:"userType",
  // editable: true,
  //   cellEditor: 'agSelectCellEditor',
  //     cellEditorParams: {
  //         values: ['AliceBlue', 'AntiqueWhite', 'Aqua', /* .... many colours */ ],
  //         valueListMaxHeight: 120,
  //         valueListMaxWidth: 120
  //     }
  // },
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    {
      field: "subscriptionId", headerName: 'Sub ID', cellRenderer: (params) => (
        <div className='col-md-5'>
          <LinkCellRenderer value={params.data.subscriptionId} />
        </div>
      ),
    },
    {
      field: "userType", headerName: 'User '
    },
    { field: "charge", headerName: 'Charge(Rs) Including GST', editable: true, onCellValueChanged: handleChargeUpdate },
    { field: "name", headerName: 'Name' },
    { field: "defaultPacakge", headerName: 'Default Package' },
    { field: "monthlyDiscountPercentage", headerName: 'Monthly Disc' },
    { field: "yealryDiscountPercentage", headerName: 'Yearly Disc' },
    { field: "quaterlyDiscountPercentage", headerName: 'Quarter Disc' },
    { field: "gstPercentage", headerName: 'GST(%)' },
    {
      headerName: 'subscriptionAllowed',
      field: 'subscriptionAllowed',
      cellRenderer: (params) => (
        <Input
          type="checkbox"
          checked={params.value}
          onChange={() => handleCheckboxChange(params.rowIndex, params)}
        />
      ),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <div className='col-md-5'>
          <Button outline onClick={() => handleView(params.data)} style={{ marginRight: '10px' }}>
            View
          </Button>
          <Button outline onClick={() => handleAdd(params.data)}>
            Add
          </Button>
        </div>
      ),
    },

  ]);
  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab"],
  }
  const getRowData = () => {
    let rowData = [];
    gridApi.forEachNode(node => rowData.push(node.data));
    alert(JSON.stringify(rowData))
  }
  const gridOptions = {
    defaultColDef: {
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressRowClickSelection: true,
    groupSelectsChildren: true,
    // debug: true,
    rowSelection: "multiple",
    rowGroupPanelShow: "always",
    pivotPanelShow: "always",
    columnDefs: colDefs,
    pagination: true,

  };
  return (
    <React.Fragment>

      <h4>All Subscription List</h4>

      {loading === true ? <div className="loader-container text-center">
        <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Loader Spinner */}
      </div> :
        <div
          className="ag-theme-alpine" // applying the grid theme
          style={{ height: 500 }} // the grid will fill the size of the parent container
        >



          <br></br>

          <AgGridReact
            onGridReady={onGridReady}
            rowData={rowData}
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
          />

        </div>
      }
    </React.Fragment>
    // wrapping container with theme & size

  )
}
const SubList = (props) => {
  const [state, setState] = useState({
    username: "admin",
    password: "test123",
    charge: 0.0,
    userType: "",
    data: [],
    errors: [],
    values: []
  });
  const [loader, setLoader] = useState(false)
  useEffect(() => {

    getAllPackage();
  }, loader);
  const getAllPackage = async () => {
    try {
      setLoader(true)
      const response = await get("/subscription/list");
      console.log(response.data)

      setLoader(false);
      setState(prevState => ({ ...prevState, data: response.data }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>


                <Row>
                  {state.data!=undefined && state.data && state.data.length > 0 ? <GridExample data={state.data} /> : ''}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {})(SubList));

