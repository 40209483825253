
export const rechargeTypes = [
    {
        id: 0,
        name: "Mobile",
        icon: " ri-smartphone-fill",
        path:"/mobile"
    },
    {
        id: 1,
        name: "DTH",
        icon: " ri-radar-line",
        path:""
    },
    {
        id: 2,
        name: "Electricity",
        icon: " ri-lightbulb-flash-line",
            path:""
    },
    {
        id: 3,
        name: "Credit Card",
        icon: " ri-bank-card-line",
            path:""
    },
    {
        id: 4,
        name: "EMI Payments",
        icon: "dripicons-card",
            path:""
    },
    {
        id: 5,
        name: "Insurance Premium",
        icon: " dripicons-dots-3",
        path:""
    },
    {
        id: 8,
        name: "FASTag",
        icon: "fas fa-tags",
        path:""
    },
    {
        id: 9,
        name: "Landline",
        icon: "fas fa-link",
            path:""
    },
    {
        id: 10,
        name: "Broadband",
        icon: "",
            path:""
    },
    {
        id: 11,
        name: "Piped Gas",
        icon: "",
            path:""
    },
    {
        id: 12,
        name: "LPG Booking",
        icon: "",
            path:""
    },
    {
        id: 13,
        name: "Water",
        icon: "",
            path:""
    },

    {
        id: 14,
        name: "Digital Cable TV",
        icon: "",
            path:""
    },
    {
        id: 15,
        name: "Data Card",
        icon: "",
            path:""
    },
    {
        id: 16,
        name: "Traffic Challan",
        icon: "",
            path:""
    },
    {
        id: 17,
        name: "Property Tax",
        icon: "",
    },
    {
        id: 18,
        name: "Housing Payments",
        icon: "",
            path:""
    },
    {
        id: 19,
        name: "Fee Payment",
        icon: "",
            path:""
    },
    {
        id: 20,
        name: "Hospitals",
        icon: "",
    },
    {
        id: 21,
        name: "Clubs and Associations",
        icon: "",
            path:""
    },
    {
        id: 22,
        name: "Zip/PayLater",
        icon: "",
            path:""
    },
    {
        id: 23,
        name: "Subscriptions",
        icon: "",
            path:""
    },
]



export const bankingServiceTypes = [
    {
        id: 0,
        name: "Aeps",
        icon: " ri-smartphone-fill",
        path:"/mobile"
    },
    {
        id: 1,
        name: "Aadhar Pay",
        icon: " ri-radar-line",
        path:""
    },
    {
        id: 2,
        name: "DMT",
        icon: " ri-lightbulb-flash-line",
            path:""
    },
    {
        id: 3,
        name: "Cash Deposit",
        icon: " ri-bank-card-line",
            path:""
    }
    // {
    //     id: 4,
    //     name: "EMI Payments",
    //     icon: "dripicons-card",
    //         path:""
    // },
    // {
    //     id: 5,
    //     name: "Insurance Premium",
    //     icon: " dripicons-dots-3",
    //     path:""
    // },
    // {
    //     id: 8,
    //     name: "FASTag Topup",
    //     icon: "fas fa-tags",
    //     path:""
    // }
]
