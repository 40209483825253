import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = localStorage.getItem('token')
const user=localStorage.getItem('authUser');
// if(user!=null){
// token=JSON.parse(user).jwt;
// console.log(token,";;;;;",user)

// }

//apply base url for axios
//export const API_URL = "https://grahaksathi.com/api/v1/"
// const API_URL = "http://ec2-3-109-133-160.ap-south-1.compute.amazonaws.com:8082/swagger-ui/index.html"
export const API_URL = "https://grahaksathi.com/api/v1"
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = "Bearer "+token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url,params, config = {}) {

  if (params) {
    var queryString = params
        ? Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&')
        : '';
    return  axiosApi.get(`${url}?${queryString}`, {...config}).then(response => response.data);
} else {
   return  axiosApi.get(`${url}`,{...config}).then(response => response.data);
}
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err=>err.response)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

