import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import {
  Card, Button, CardTitle, CardBody, Pagination,
  PaginationItem,
  PaginationLink, CardSubtitle, CardText, Label, Container, Row, Modal, Col, Table, ModalHeader, ModalBody, ModalFooter, Input
} from "reactstrap";
import AddPackage from "./addPackage";
import { useHistory } from "react-router-dom";
import { get } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AvField, AvForm } from "availity-reactstrap-validation/lib/AvField";

const AssignedPackageList = () => {
  const dispatch = useDispatch();
  const counter = useSelector(state => state.packages)
  const [modal, setModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [assinedPackages, setAssignedPackages] = useState({});
  const history = useHistory();

  // const [toggle,setToggle]=useState(false);
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);

  useEffect(() => {
    let data = { page: 0, size: 12 };
    getAllPackages("/assignPackages",data);
    console.log(counter)
  }, [])

  const toggle = () => setModal(!modal);
  const handlePagination = (e, currentPage) => {
    console.log(currentPage)
    e.preventDefault();
    setCurrentState(currentPage);
    let data = { page: currentPage, size: 12 };
    getAllPackages("/assignPackages",data);

  };



  const getAllPackages = async (url,data) => {

    try {
      let datas = await get(url, data);
      setPageState(datas.totalPages);

      toast.success("Fecthe", {
        position: toast.POSITION.TOP_LEFT
      });

      setAssignedPackages(datas);

    } catch (err) {

      toast.error(err.response.data.detail, {
        position: toast.POSITION.TOP_LEFT
      });
    }




  }


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(e.target.value)
    let data = { page: 0, size: 1200,searchtext:e.target.value };
    getAllPackages("/searchAssignedPackages",data);


  };



  return (
    <>

      <React.Fragment>
        <div className="page-content">


          <Modal isOpen={modal} toggle={toggle} >
            <ModalHeader toggle={toggle}>Create Package</ModalHeader>
            <ModalBody>
              <AddPackage />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <ToastContainer />

          <Container fluid>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Packages </h4>


                    <Row>
                      <Col md="6">
                        <Button
                          color="primary"
                          outline
                          onClick={() => { setModal(true) }}
                        >
                          Add Package
                        </Button>
                      </Col>
                      <Col md="6">

                        <Input
                          className="mb-3"
                          name="pan"
                          placeholder="Search user"
                          type="text"
                          onChange={handleSearch}
                        />
                      </Col>
                    </Row>



                    <div className="table-responsive">
                      <Table striped className="mb-0">

                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Package ID</th>

                            <th>User</th>
                            <th>Service</th>
                            <th>Action</th>

                          </tr>
                        </thead>
                        <tbody>
                          {assinedPackages.assignedList != undefined && assinedPackages.assignedList.map(function (data, index) {
                            return <tr key={index}>
                              <td>{data.id}</td>
                              <td>{data.packageId}</td>
                              <td>{data.loginId}</td>
                              <td>{data.serviceType}</td>
                              <td><Button
                                color="primary"
                                outline
                              >
                                <a href={`/packagecommision/${data.packageId}`}>Edit</a>
                              </Button></td>

                              <td>
                                <Button
                                  color="danger"
                                  outline
                                >
                                  Delete
                                </Button>

                              </td>
                            </tr>;
                          })}

                        </tbody>
                      </Table>
                    </div>


                  </CardBody>
                </Card>
              </Col>


              <Pagination aria-label="pagination">
                <PaginationItem disabled={currentState <= 0}>
                  <PaginationLink
                    onClick={e => handlePagination(e, currentState - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>

                {[...Array(pageState)].map((_, i) => (
                  <PaginationItem active={i === currentState} key={i}>
                    <PaginationLink onClick={e => handlePagination(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={currentState >= pageState - 1}>
                  <PaginationLink
                    onClick={e => handlePagination(e, currentState + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>
              </Pagination>

            </Row>
          </Container>
        </div>
      </React.Fragment>

    </>
  )
}

export default AssignedPackageList;