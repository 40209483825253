import React from 'react';
import { Redirect } from 'react-router-dom';

// Mock function to get user role
const getUserRole = () => {
  // Replace this with actual logic to get user role
  return JSON.parse(localStorage.getItem('authUser')).role;
};

const RoleBasedRedirect = () => {
  const role = getUserRole();
console.log(role)
  if (role === 'ADMIN') {
    return <Redirect to="/superadmin" />;
  } else if (role === 'RETAILER') {
    return <Redirect to="/retailer" />;
  } else {
    return <Redirect to="/" />;
  }
};

export default RoleBasedRedirect;
