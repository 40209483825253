import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { post } from '../../helpers/api_helper';

const AddDist = ({ buttonLabel, className, modal, toggle,refreshUsers }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await post(`/distributor/onboard?mobileNumber=${mobileNumber}`);
            console.log(response.data);
            refreshUsers();
            toggle()
        } catch (error) {
            console.error("Error fetching packages:", error);
        } finally {
            setIsLoading(false);
            toggle(); // Close the modal after submit
        }
    };

    return (
        <div>
            <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Add Distributor</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="mobileNumber">Mobile Number</Label>
                        <Input
                            type="text"
                            name="mobile"
                            id="mobileNumber"
                            placeholder="Enter your mobile number"
                            value={mobileNumber}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : 'Create'}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle} disabled={isLoading}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AddDist;
