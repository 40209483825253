import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner, Col } from 'reactstrap';
import { get, post, put } from '../../helpers/api_helper';
import Select from 'react-select'

const UpgradeSub = ({ selectedData,buttonLabel, className, modal, toggle,refreshUsers }) => {
    const [mobileNumber, setMobileNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [walletType,setWalletType]=useState("DMT");
    const[subOptions,setSubOptions]=useState([]);
    const [loader, setLoader] = useState(false)
    const [selectedSubs,setSelectedSubs]=useState('');

    useEffect(() => {
        getAllSubs();
        console.log(selectedData)
      },[]);

    const getAllSubs = async () => {
        try {
          setLoader(true)
          const response = await get("/subscription/list");
          console.log(response.data)
          setLoader(false);
          let data=response.data.map(data=>{
            return { value: data.subscriptionId, label: data.subscriptionId+'-'+data.name+'-'+data.userType };
          })
          console.log(data)
          setSubOptions(data);

        } catch (error) {
          console.error("Error fetching packages:", error);
        }
      };

    const handleInputChange = (e) => {
        setMobileNumber(e.target.value);
    };
    const handleServiceChange=(ev)=>{
       setWalletType(ev.target.value)
          }
    const handleSubmit = async (ev) => {
        console.log(selectedData,selectedSubs)
        setIsLoading(true);
        try {
            const response = await put('/subscription/allow/user/'+selectedData.id+'/upgrade/'+selectedSubs.value.toString());
            console.log(response);
            refreshUsers();
            toggle()
        } catch (error) {
            console.error("Error Upgrading packages:", error);
        } finally {
            setIsLoading(false);
            toggle(); // Close the modal after submit
        }
    };

    return (
        <div>
            
            <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Package Upgrade For  {selectedData.mobile}</ModalHeader>
                <ModalBody>
                {loader ? <div className="loader-container text-center">
                  <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Loader Spinner */}
                </div> : ""}
                    <FormGroup>
                        <Label for="mobileNumber">Select SUbscription type</Label>
                        <Select options={subOptions}  onChange={(newValue) => {
          setSelectedSubs(newValue)
        }} />
                    </FormGroup>
                    


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : 'Create'}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle} disabled={isLoading}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default UpgradeSub;
