import { GET_ALL_PACKAGE ,PACKAGE_SUCCESS,PACKAGE_FAIL} from "./actionTypes"

export const getAllPackage = ( data,history) => {
    return {
        type: GET_ALL_PACKAGE,
        payload: { data,history }
    }
}

export const fetchPackageSuccessFull = (packagedata) => {
    return {
        type: PACKAGE_SUCCESS,
        payload: packagedata
    }
}

export const fetchPackageFail = (packagedata) => {
    return {
        type: PACKAGE_FAIL,
        payload: packagedata
    }
}
