import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  requiredRoles,
  ...rest
}) => {
  if (isAuthProtected && !localStorage.getItem("authUser")) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
    );
  }
  if (requiredRoles && requiredRoles.length > 0) {
    const userRoles = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).role
      : "";
    const hasAccess = requiredRoles.some((role) => userRoles===role);
    if (!hasAccess) {
      return <Redirect to="/login" />;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
           <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
