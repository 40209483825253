import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "./index.css";
import data from "../../common/data/data.json";

const BroadbandRechargeForm = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [operatorFields, setOperatorFields] = useState([]);

  const handleValidSubmit = (event) => {
    event.preventDefault();
    // recharge logic here
  };

  const broadbandData = data.category.find(
    (category) => category.categoryId === "broadband"
  );
  const billerRoot = broadbandData?.billerRoot;

  const handleOperatorChange = (event) => {
    const selectedOperator = event.target.value;
    setSelectedOperator(selectedOperator);

    const operator = billerRoot.find((biller) => biller.name === selectedOperator);
    const fields = operator?.billers[0]?.fields || [];
    setOperatorFields(fields);
  };

  return (
    <div className="container">
      <div className="RechargeForm">
        <Card
          className="text-center"
          body
          inverse
          style={{ backgroundColor: "rgb(238, 82, 82)" }}
        >
          <CardBody>
            <CardTitle tag="h5" className="text-white text-3xl">
              Broadband Recharge Form
            </CardTitle>
            <Form onSubmit={handleValidSubmit}>
              <FormGroup>
                <Label for="operator">Select Operator</Label>
                <Input
                  type="select"
                  name="operator"
                  id="operator"
                  onChange={handleOperatorChange}
                >
                  <option value="">Select operator</option>
                  {billerRoot &&
                    billerRoot.map((biller) => (
                      <option key={biller.name} value={biller.name}>
                        {biller.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>

              {operatorFields.map((field) => (
                <FormGroup key={field.id}>
                  <Label for={field.id}>{field.name}</Label>
                  <Input
                    type={field.type}
                    name={field.id}
                    id={field.id}
                    placeholder={field.name}
                    required
                    value={field.id === "cn" ? accountNumber : amount}
                    onChange={(e) =>
                      field.id === "cn"
                        ? setAccountNumber(e.target.value)
                        : setAmount(e.target.value)
                    }
                  />
                </FormGroup>
              ))}

              <Button color="primary" type="submit" className="mt-4">
                Recharge Now
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BroadbandRechargeForm;
