import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { post } from '../../helpers/api_helper';

const FreezeModal = ({ selectedData,buttonLabel, className, modal, toggle,refreshUsers }) => {
    const [mobileNumber, setMobileNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [walletType,setWalletType]=useState("");

    const handleInputChange = (e) => {
        setMobileNumber(e.target.value);
    };
    const handleServiceChange=(ev)=>{
       setWalletType(ev.target.value)
          }
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let data={
                "userId": selectedData.id,
                "amount": mobileNumber,
                "walletType": walletType
              }
            const response = await post('/pgpayment/update/freeze-amount',data);
            if(response.success){
                console.log(response);
                refreshUsers();
                toggle()
            }else{
                alert(response.message)
            }
          
        } catch (error) {
            console.error("Error fetching packages:", error);
        } finally {
            setIsLoading(false);
            toggle(); // Close the modal after submit
        }
    };

    return (
        <div>
            <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Add Freeze Balance for {selectedData.mobile}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="mobileNumber">Freeze Amt</Label>
                        <Input
                            type="number"
                            name="balance"
                            id="balance"
                            placeholder="Enter Amount to Freeze"
                            value={mobileNumber}
                            onChange={handleInputChange}
                        />
                    </FormGroup>

                    <FormGroup>
                    <Label for="mobileNumber">Wallet type</Label>
                    <Input
      id="exampleSelect"
      name="Select Wallet Type"
      type="select"
      onChange={handleServiceChange}
      value={walletType}
    >
      <option value={'AEPS'}>
        AEPS
      </option>
      <option value={'DMT'}>
        DMT
      </option>
      
    </Input>

                    </FormGroup>
                    


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : 'Create'}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle} disabled={isLoading}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default FreezeModal;
