import React, {
  Component, StrictMode, useState, useMemo, useCallback,
} from 'react';

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import { Card, Spinner, Button, CardTitle, ButtonGroup, CardBody, CardSubtitle, CardText, Container, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Row, Col, Table, Label, Alert, Form, FormGroup, Input } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';

import { LicenseManager } from 'ag-grid-enterprise'
import "ag-grid-charts-enterprise";

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { connect } from 'react-redux';
import { get, post } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// availity-reactstrap-validation
import { AvForm, AvField, AvRadio } from 'availity-reactstrap-validation';
import AddDist from './AddDist';

const GridExample = (props) => {
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  
   LicenseManager.setLicenseKey("SDDDD")
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const gridRef = useRef();
  // Row Data: The data to be displayed.
  useEffect(() => {
    console.log(props.data)
    setRowData(props.data)
  });


  const LinkCellRenderer = (props) => {
    const { value } = props;
    const url = `/subscribe/view/${value}`; // Construct your URL here

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    );
  };

  const WalletRenderer = (props) => {
    const { data } = props;
    const filteredData = data.filter(item => item.walletType === props.walletName);
    console.log(props, filteredData);

    return (
      <div>
        {filteredData.length > 0 && filteredData[0].balance}
      </div>
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleView = (row) => {
    // Handle the view action
    alert(`Viewing details of ${row.name}`);
  };

  const handleAdd = (row) => {
    // Handle the add action
    alert(`Adding ${row.name}`);
  };

  const refreshUsers = () => {
    props.getAllUsers();
  }
  const onPaginationChanged = () => {
    if (gridApi) {
      console.log(gridApi)
      const currentPage = gridApi.paginationGetCurrentPage();
      const pageSize = gridApi.paginationGetPageSize();
      //getAllUsers(currentPage, pageSize);
    }
  };

  const handleCheckboxChange = (rowIndex, param) => {
    console.log(rowData, param)

    let rowDataA = [];
    param.api.forEachNode(node => rowDataA.push(node.data));
    console.log(rowDataA)
  };

  const [colDefs, setColDefs] = useState([
    // {
    //   headerName: "Country",
    //   showRowGroup: "transactionReference",
    //   cellRenderer: "agGroupCellRenderer",
    //   minWidth: 200,
    // },
    { field: "transactionId", headerName: 'ID', filter: "agMultiColumnFilter" },
    { field: "transactionReference", headerName: 'TranRef',rowGroup: true },
    { field: "transactionAmountDebit", headerName: 'Debit Amt' },
    { field: "transactionAmountCredit", headerName: 'Crdt Amt' },
    { field: "closingBalance", headerName: 'Cl Bal.' },
    { field: "transactionStatus", headerName: 'Status' },
    {
      field: "transactionType", headerName: 'Type',sortable: true
    },
    { field: "subTransactionType", headerName: 'Sub Type' },
    { field: "transactionRemarks", headerName: 'Remark',sortable: true },
    { field: "transactionDate", headerName: 'Date Trans' },
    { field: "gender", headerName: 'Gender' },
    { field: "alternateMobile", headerName: 'Alt Mob' },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <div className='col-md-5'>
          <Button outline onClick={() => handleView(params.data)} style={{ marginRight: '10px' }}>
            Disable
          </Button>
          <Button outline onClick={() => handleAdd(params.data)}>
            Lock
          </Button>
        </div>
      ),
    },

  ]);

  const handleServiceChange=(ev)=>{
console.log(ev.target.value)
props.handleServiceChange(ev.target.value)
  }
  const onFilterTextBoxChanged = useCallback(() => {
    let datalist = props.data;
    let query = document.getElementById("filter-text-box").value;
    console.log(datalist, query)
    let datafiltered = datalist.filter(item =>
      Object.values(item).some(
        value => value != null && value.toString().toLowerCase().includes(query.toLowerCase())
      )
    );
    console.log(datafiltered)
    if (gridRef) {
      gridRef.current.api.setRowData(datafiltered);  // Update the grid's data

    }

  }, []);
  const onRowSelected = (event) => {
    const selectedNode = event.node;
    const selectedData = selectedNode.data;
    console.log('Selected Node:', selectedNode);
    console.log('Selected Data:', selectedData);
  };
  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab"],
  }
  const getRowData = () => {
    let rowData = [];
    gridApi.forEachNode(node => rowData.push(node.data));
    alert(JSON.stringify(rowData))
  }
  function isFirstColumn(
    params
  ) {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
  const gridOptions = {
    defaultColDef: {
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      checkboxSelection: isFirstColumn,

    },
    suppressRowClickSelection: true,
    groupSelectsChildren: true,
    // debug: true,
    rowGroupPanelShow: "always",
    pivotPanelShow: "always",
    columnDefs: colDefs,


  };

  const autoGroupColumnDef = {
    headerName: 'Trans Ref ID',
    field: 'transactionReference',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true
    }
  };
  return (
    <React.Fragment>
      <div className="d-flex p-1 m-1 justify-content-left">
        {modal ? <AddDist modal={modal} toggle={toggle} refreshUsers={refreshUsers} /> : ""}

        <Form>
          <Row className="row-cols-lg-auto g-4 align-items-center">
            <Col>
              <UncontrolledDropdown
                className="me-2"
                direction="end"
              >
                <DropdownToggle
                  caret
                  color="secondary"
                >
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    Freeze Amount
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    Another Action
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>

            <Col>
              <Button onClick={toggle}>Add Distributor</Button>
            </Col>

            <Col>
              <Input
              id="filter-text-box"
                type="text"
                onInput={onFilterTextBoxChanged}
                placeholder="Search by Name or Phone..."
                style={{ marginBottom: '10px' }}
              />
            </Col>

            <Col md="5">
            <Input
      id="exampleSelect"
      name="Select Wallet Type"
      type="select"
      onChange={handleServiceChange}
    >
      <option value={'AEPS'}>
        AEPS
      </option>
      <option value={'DMT'}>
        DMT
      </option>
      
    </Input>
            </Col>
          </Row>
        </Form>
      </div>



      <div
        className="ag-theme-alpine" // applying the grid theme
        style={{ height: 800 }} // the grid will fill the size of the parent container
      >



        <br></br>

        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={rowData}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          onPaginationChanged={onPaginationChanged}
          onRowSelected={onRowSelected}
          groupDisplayType={"custom"}
   

        />
      </div>

    </React.Fragment>
    // wrapping container with theme & size

  )
}
const TransactionList = (props) => {

  const [state, setState] = useState({
    username: "admin",
    password: "test123",
    charge: 0.0,
    userType: "",
    data: [],
    errors: [],
    values: []
  });
  const [loader, setLoader] = useState(false)
  useEffect(() => {

    getAllUsers();
  }, loader);

  const handleServiceChange=async (service)=>{
    try {
      let data = { "pageNo": 0, "pageSize": 10000 }
      setLoader(true)
      const response = await post("/pgpayment/wallet/"+service+"/transaction", data);
      console.log(response.data)

      setLoader(false);
      setState(prevState => ({ ...prevState, data: response.data.content }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  }

  const getAllUsers = async () => {
    try {
      let data = { "pageNo": 0, "pageSize": 10000 }
      setLoader(true)
      const response = await post("/pgpayment/wallet/DMT/transaction", data);
      console.log(response.data)

      setLoader(false);
      setState(prevState => ({ ...prevState, data: response.data.content }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>

                {loader ? <div className="loader-container text-center">
                  <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Loader Spinner */}
                </div> : ""}

                <Row>
                  {state.data.length > 0 ? <GridExample handleServiceChange={handleServiceChange} data={state.data} getAllUsers={getAllUsers} /> : ''}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {})(TransactionList));

