
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";
import Axios from "axios";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";

class RequestResetPass extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.resetPasword(values)
    }

  resetPasword=async (data)=>{

    try{
        let dataRes=await Axios.post('http://ec2-3-109-133-160.ap-south-1.compute.amazonaws.com:8082/api/account/reset-password/init',data);
      
        this.setState({packageName:''});
        toast.success("Reset Passsword Lint Sent to Your Regsitered Email", {
            position: toast.POSITION.TOP_LEFT
          });
      }catch(err){
        this.setState({error:err.response.data.message})
      console.log(err.response.data.message)
      }
  

    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <ToastContainer/>

                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/" className="logo"><img src='https://justpe.in/wp-content/uploads/2024/04/Just-2.png' height="20" alt="logo" /></Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                        <p className="text-muted">Reset your password to JustPe.</p>
                                                    </div>

                                                    <div className="p-2 mt-5">
                                                        {this.props.forgetError && this.props.forgetError ?
                                                            <Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
                                                        {
                                                            this.props.message ?
                                                                <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                        }
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="email">Email</Label>
                                                                <AvField name="email" value={this.state.username} type="email" validate={{ email: true, required: true }} className="form-control" id="email" placeholder="Enter email" />
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>Don't have an account ? <Link to="/login" className="fw-medium text-primary"> Log in </Link> </p>
                                                        <p>© 2021 JustPe. Crafted with <i className="mdi mdi-heart text-danger"></i> by JustPe</p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(RequestResetPass)
);
